import clsx from 'clsx';
import {findIndex, findLastIndex, isEqual, isNumber} from 'lodash';
import {
  ChangeEvent,
  FC,
  Fragment,
  KeyboardEventHandler,
  MouseEvent,
  PropsWithChildren,
  ReactElement,
  RefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
  useContext,
} from 'react';
import {NavLink} from 'react-router-dom';
import {twMerge} from 'tailwind-merge';
import {Portal} from '../../external/components/Portal/Portal';
import {useSubjectSource} from '../../external/helpers';
import {Checkbox, CheckboxSize} from '../Checkbox/Checkbox';
import {Icon, IconColor, IconProps, IconSize, IconSvg} from '../Icon/Icon';
import {Highlight} from '../../external/components/Highlight/Highlight';
import {VirtualList} from '../../external/components/VirtualList/VirtualList';
import {Level, LevelContext} from '../../external/context/LevelContext';

export enum DropdownListWidth {
  FULL = 'FULL',
  FIT = 'FIT',
  AS_ANCHOR = 'AS_ANCHOR',
}

export enum DropdownListSize {
  SM = 'SM',
  MD = 'MD',
}

export type DropdownListOption<VALUE = string> = {
  label: string;
  value: VALUE;
  sublabel?: string;
  disabled?: boolean;
  href?: string;
  group?: string;
  disableClick?: boolean;
  isDefault?: boolean;
  maxLines?: 1 | 2;
  icon?: ReactElement<IconProps>;
};

export type DropdownListOptions = DropdownListOption[];
export type DropdownListOptionValue = DropdownListOption['value'];

export enum IconPolicy {
  ALWAYS_VISIBLE = 'ALWAYS_VISIBLE',
  SHOW_ON_HOVER = 'SHOW_ON_HOVER',
}

export type DropdownListProps = {
  options: DropdownListOptions;
  value?: DropdownListOptionValue[];
  onChange?: (value: DropdownListOptionValue[]) => void;
  onClose?: () => void;
  isOpen?: boolean;
  anchorEl?: RefObject<HTMLElement> | null;
  className?: string | undefined;
  activeOptionClassName?: string | undefined;
  width?: DropdownListWidth | number;
  size?: DropdownListSize;
  multiple?: boolean;
  selectable?: boolean;
  useSearch?: boolean;
  defaultOpenGroups?: string[];
  groupsAlwaysOpened?: boolean;
  dropUp?: boolean;
  maxLines?: DropdownListOption['maxLines'];
  iconPolicy?: IconPolicy;
};

export const DropdownList: FC<PropsWithChildren<DropdownListProps>> = ({
  options,
  value = [],
  onChange = () => undefined,
  onClose = () => undefined,
  isOpen = false,
  anchorEl = null,
  className = undefined,
  activeOptionClassName = '',
  width = anchorEl ? DropdownListWidth.AS_ANCHOR : DropdownListWidth.FIT,
  size = DropdownListSize.MD,
  multiple = false,
  selectable = true,
  useSearch = false,
  children = undefined,
  defaultOpenGroups = [],
  groupsAlwaysOpened = false,
  dropUp = false,
  maxLines = 2,
  iconPolicy = IconPolicy.ALWAYS_VISIBLE,
}) => {
  const level = useContext(LevelContext);
  const [searchPhrase, setSearchPhrase] = useState<string | null>(null);
  const [selectedValues, setSelectedValues] = useState<DropdownListOptionValue[]>(value);
  const valueRef = useRef(value);
  const scrollSubject = useSubjectSource<number>();
  const actionDevice = useRef<'mouse' | 'keyboard'>('mouse');
  const [activeOptionIndex, setActiveOptionIndex] = useState<number>(-1);

  const handleHover = (optionIndex: number) => {
    if (actionDevice.current === 'mouse') {
      setActiveOptionIndex(optionIndex);
    }
  };

  const [openGroups, _setOpenGroups] = useState(defaultOpenGroups);
  const setOpenGroup = (name: string, nextIsOpen: boolean) => {
    if (!groupsAlwaysOpened) {
      _setOpenGroups(() => (nextIsOpen ? [...openGroups, name] : openGroups.filter(s => s !== name)));
    }
  };

  const isOpenGroup = (name: string) => groupsAlwaysOpened || openGroups.includes(name);
  const startsAt = (str: string, subStr: string): number => str.toLowerCase().indexOf(subStr.toLowerCase());

  const filteredOptions = useMemo(() => {
    return options.filter(
      option => !searchPhrase || searchPhrase.length === 0 || startsAt(option.label, searchPhrase) !== -1,
    );
  }, [options, searchPhrase]);

  useEffect(() => {
    if (!isEqual(valueRef.current, value)) {
      setSelectedValues(value);
      valueRef.current = value;
    }
  }, [value]);

  useEffect(() => {
    requestAnimationFrame(() => scrollSubject.next(activeOptionIndex));
  }, [activeOptionIndex]);

  useEffect(() => {
    setActiveOptionIndex(-1);
  }, [searchPhrase]);

  useEffect(() => {
    if (!isOpen) {
      setActiveOptionIndex(-1);
      setSearchPhrase(null);
    }
  }, [isOpen]);

  const scrollBoxRef = useRef<HTMLDivElement>(null);

  const isOptionSelected = (option: DropdownListOption): boolean => selectedValues.includes(option.value);

  const isOptionDisabled = (option: DropdownListOption): boolean => {
    return (!multiple && isOptionSelected(option)) || !!option.disabled;
  };

  const handleOptionSelect = (option: DropdownListOption) => {
    let nextSelectedValues: DropdownListOptionValue[];
    if (!multiple) {
      nextSelectedValues = [option.value];
    } else {
      nextSelectedValues = selectedValues.includes(option.value)
        ? selectedValues.filter(v => v !== option.value)
        : [...selectedValues, option.value];
    }

    if (selectable) {
      setSelectedValues(nextSelectedValues);
    }

    if (!isOptionDisabled(option)) {
      onChange(nextSelectedValues);
    } else {
      onClose();
    }
  };

  const handleGroupSelect = (valuesInGroup: DropdownListOptionValue[], checked: boolean): void => {
    const nextSelectedValues = checked
      ? [...selectedValues.filter(_value => !valuesInGroup.includes(_value)), ...valuesInGroup]
      : [...selectedValues.filter(_value => !valuesInGroup.includes(_value))];

    if (selectable) {
      setSelectedValues(() => nextSelectedValues);
    }

    onChange(nextSelectedValues);
  };

  const handleKeyDown = (keyCode: string) => {
    if (keyCode === 'Enter' || keyCode === 'Space') {
      handleOptionSelect(filteredOptions[activeOptionIndex]);
    }
    if (keyCode === 'ArrowUp') {
      setActiveOptionIndex(prevActiveIndex => {
        const nextIndex = findLastIndex(filteredOptions, option => !isOptionDisabled(option), prevActiveIndex - 1);
        return nextIndex !== -1 ? nextIndex : findLastIndex(filteredOptions, option => !option.disabled);
      });
    }
    if (keyCode === 'ArrowDown') {
      setActiveOptionIndex(prevActiveIndex => {
        const nextIndex = findIndex(filteredOptions, option => !isOptionDisabled(option), prevActiveIndex + 1);
        return nextIndex !== -1 ? nextIndex : findIndex(filteredOptions, option => !option.disabled);
      });
    }
  };

  const getLabel = (option: DropdownListOption, isDisabled: boolean, index = -1): ReactElement => {
    return (
      <div>
        <Highlight
          className={clsx('whitespace-pre-wrap', {
            'line-clamp-1': (option.maxLines || maxLines) === 1 || option.sublabel,
            'line-clamp-2': (option.maxLines || maxLines) === 2 && !option.sublabel,
          })}
          text={option.label}
          phrase={searchPhrase || ''}
          highlightClassName={twMerge(
            clsx('bg-primary-100', {
              'bg-primary-200': activeOptionIndex === index && isOptionSelected(option),
            }),
          )}
        />
        {option.sublabel && (
          <div
            className={clsx('line-clamp-1', 'text-xs', {
              'text-grey-500': !isDisabled && activeOptionIndex !== index,
              'text-primary-600': !isDisabled && activeOptionIndex === index,
              'text-grey-300': isDisabled && !isOptionSelected(option),
              'text-primary-500': isOptionSelected(option),
            })}
          >
            {option.sublabel}
          </div>
        )}
      </div>
    );
  };

  const renderGroupCheckbox = (
    optionIndex: number,
    hasSublabel: boolean,
    isDisabled: boolean,
  ): ReactElement | undefined => {
    const groupName = options[optionIndex].group;

    if (groupName === undefined || options.findIndex(option => option.group === groupName) < optionIndex) {
      return undefined;
    }

    const optionsInGroup = options.filter(o => o.group === groupName);
    const valuesInGroup = optionsInGroup.map(o => o.value);
    const checkedOptionsInGroup = optionsInGroup.filter(o => selectedValues.includes(o.value));

    return (
      <div
        key={groupName}
        className={clsx('rounded-lg cursor-pointer px-[16px] shrink-0 flex items-center relative text-black', {
          'h-[40px]': size === DropdownListSize.SM,
          'h-[48px]': size === DropdownListSize.MD,
        })}
      >
        <div className={clsx('flex w-full h-full items-center')}>
          {!groupsAlwaysOpened && (
            <Icon
              svg={isOpenGroup(groupName) ? IconSvg.KEYBOARD_ARROW_UP : IconSvg.KEYBOARD_ARROW_DOWN}
              size={IconSize.LG}
              className={twMerge(
                clsx('shrink-0 mr-[8px] filter-grey-900', {'filter-primary-700': activeOptionIndex === optionIndex}),
              )}
            />
          )}
          <div
            onClick={() => {
              if (!groupsAlwaysOpened) {
                setOpenGroup(groupName, !isOpenGroup(groupName));
              } else {
                handleGroupSelect(valuesInGroup, checkedOptionsInGroup.length !== optionsInGroup.length);
              }
            }}
            className={clsx(
              'w-full h-full flex items-center appearance-none focus:outline-none before:absolute before:left-[8px] before:w-[calc(100%-16px)] before:rounded-lg',
              {
                'before:h-[24px]': size === DropdownListSize.SM && !hasSublabel,
                'before:h-[40px]': size === DropdownListSize.SM && hasSublabel,
                'before:h-[32px]': size === DropdownListSize.MD && !hasSublabel,
                'before:h-[48px]': size === DropdownListSize.MD && hasSublabel,
              },
            )}
          >
            <Checkbox
              size={CheckboxSize.SM}
              checked={checkedOptionsInGroup.length === optionsInGroup.length}
              moderate={
                !groupsAlwaysOpened &&
                checkedOptionsInGroup.length > 0 &&
                checkedOptionsInGroup.length < optionsInGroup.length
              }
              value={groupName}
              disabled={isDisabled}
              onChange={(checked: boolean, event) => {
                (event as MouseEvent<HTMLInputElement>)?.nativeEvent?.stopPropagation();
                handleGroupSelect(valuesInGroup, checked);
              }}
              className="mr-[8px] shrink-0"
            />
            {groupName}
          </div>
        </div>
      </div>
    );
  };

  const dropdownWidth = (() => {
    if (isNumber(width)) {
      return `${width}px`;
    }

    if (width === DropdownListWidth.FULL) {
      return '100%';
    }

    if (width === DropdownListWidth.AS_ANCHOR) {
      return anchorEl?.current ? `${anchorEl.current.getBoundingClientRect().width}px` : 'max-content';
    }
    return 'fit-content';
  })();

  const renderOption = (option: DropdownListOption, index: number) => {
    if (option.group !== undefined && isOpenGroup(option.group) === false) {
      return <></>;
    }
    return (
      <div
        onMouseEnter={() => handleHover(index)}
        key={option.value}
        className={twMerge(
          clsx('pr-[16px] shrink-0 flex items-center relative text-black', {
            'pl-[16px]': groupsAlwaysOpened || option.group === undefined,
            'pl-[48px]': !groupsAlwaysOpened && option.group !== undefined,
            'h-[40px]': size === DropdownListSize.SM && !option.sublabel,
            'h-[52px]': size === DropdownListSize.SM && option.sublabel,
            'h-[48px]': size === DropdownListSize.MD && !option.sublabel,
            'h-[62px]': size === DropdownListSize.MD && option.sublabel,
            'bg-primary-100 text-primary-700 rounded-lg': isOptionSelected(option),
            [`bg-primary-50 text-primary-700 rounded-lg cursor-pointer ${activeOptionClassName || ''}`]:
              !isOptionDisabled(option) && activeOptionIndex === index,
            'bg-primary-200': activeOptionIndex === index && isOptionSelected(option),
          }),
        )}
        data-testid="dropdown-child"
      >
        {!multiple && option.href && (
          <NavLink
            to={option.href}
            onClick={() => handleOptionSelect(option)}
            data-testid="dropdown-component-link"
            title={option.label}
            className={() =>
              clsx({
                'text-grey-500': isOptionDisabled(option) && !isOptionSelected(option),
                'w-full h-full flex items-center appearance-none focus:outline-none': true,
                'before:absolute before:left-[8px] before:w-[calc(100%-16px)] before:rounded-lg': true,
                'before:h-[24px]': size === DropdownListSize.SM && !option.sublabel,
                'before:h-[40px]': size === DropdownListSize.SM && option.sublabel,
                'before:h-[32px]': size === DropdownListSize.MD && !option.sublabel,
                'before:h-[48px]': size === DropdownListSize.MD && option.sublabel,
              })
            }
          >
            {getLabel(option, isOptionDisabled(option), index)}
          </NavLink>
        )}
        {!option.href && (
          <span
            title={option.label}
            onClick={() => handleOptionSelect(option)}
            className={clsx({
              'text-grey-500': isOptionDisabled(option) && !isOptionSelected(option),
              'w-full h-full flex items-center appearance-none focus:outline-none': true,
              'before:absolute before:left-[8px] before:w-[calc(100%-16px)] before:rounded-lg': true,
              'before:h-[24px]': size === DropdownListSize.SM && !option.sublabel,
              'before:h-[40px]': size === DropdownListSize.SM && option.sublabel,
              'before:h-[32px]': size === DropdownListSize.MD && !option.sublabel,
              'before:h-[48px]': size === DropdownListSize.MD && option.sublabel,
              'pointer-events-none': !isOptionDisabled(option) && option.disableClick,
            })}
          >
            {multiple && (
              <Checkbox
                size={CheckboxSize.SM}
                checked={isOptionSelected(option)}
                value={option.value}
                onChange={(_next, event) => {
                  (event as MouseEvent<HTMLInputElement>).stopPropagation();
                }}
                className={clsx('mr-[8px] shrink-0')}
              />
            )}
            {getLabel(option, isOptionDisabled(option), index)}
          </span>
        )}
        {option.icon && (
          <span
            className={clsx('flex w-[21px] grow-0 shrink-0', {
              'opacity-50': isOptionDisabled(option),
              invisible: iconPolicy === IconPolicy.SHOW_ON_HOVER && activeOptionIndex !== index,
              visible: iconPolicy === IconPolicy.SHOW_ON_HOVER && activeOptionIndex === index,
            })}
          >
            {option.icon}
          </span>
        )}
      </div>
    );
  };

  const handleSearchInputKeyDown: KeyboardEventHandler<HTMLInputElement> = ev => {
    if (['ArrowUp', 'ArrowDown'].includes(ev.key)) {
      ev.preventDefault();
    }
  };

  const virtualOptions = useMemo(() => {
    return options
      .filter(option => !searchPhrase || searchPhrase.length === 0 || startsAt(option.label, searchPhrase) !== -1)
      .reduce<ReactElement[]>((acc, option, index) => {
        const groupElement = renderGroupCheckbox(index, !!option.sublabel, !!option.disabled);
        if (groupElement) {
          acc.push(<Fragment key={option.group}>{groupElement}</Fragment>);
        }
        if (option.group === undefined || isOpenGroup(option.group)) {
          acc.push(<Fragment key={option.value}>{renderOption(option, index)}</Fragment>);
        }

        return acc;
      }, [] as ReactElement[]);
  }, [filteredOptions, openGroups, selectedValues, activeOptionIndex]);

  const render = () => (
    <div
      style={{width: dropdownWidth}}
      ref={scrollBoxRef}
      tabIndex={0}
      onMouseMove={() => {
        actionDevice.current = 'mouse';
      }}
      onKeyDownCapture={e => {
        actionDevice.current = 'keyboard';
        handleKeyDown(e.key);
      }}
      className={twMerge(
        clsx(
          'outline-none px-[8px] block bg-white border rounded-lg font-quicksand border-grey-300 shadow overflow-x-hidden',
          {
            'pt-[8px]': !useSearch,
            'pb-[8px]': !children,
          },
        ),
        className,
      )}
    >
      {useSearch && (
        <div className="flex w-full h-[48px] shrink-0 px-[16px] items-center sticky top-0 bg-white z-[2]">
          <input
            autoFocus
            onKeyDownCapture={handleSearchInputKeyDown}
            value={searchPhrase || ''}
            onChange={(event: ChangeEvent<HTMLInputElement>) => setSearchPhrase(event.target.value || '')}
            placeholder="Wyszukaj ..."
            className="appearance-none outline-none w-full"
          />
          <Icon className="grow-0 shrink-0 justify-self-end" color={IconColor.PRIMARY_500} svg={IconSvg.SEARCH} />
        </div>
      )}
      <VirtualList
        scrollToIndex={scrollSubject}
        parentRef={scrollBoxRef}
        parentFixedHeight={(useSearch ? -48 : 0) + (children ? -48 : 0)}
        rowHeightPx={48}
      >
        {virtualOptions.map(el => el)}
      </VirtualList>
      {children && (
        <LevelContext.Provider value={level + Level.Dropdown}>
          <div className="w-full flex h-[48px] shrink-0 items-center justify-center sticky bottom-0 bg-white z-[1]">
            {children}
          </div>
        </LevelContext.Provider>
      )}
    </div>
  );

  const renderPopOver = () => {
    return (
      <Portal
        backdrop="transparent"
        isOpen={!!anchorEl && isOpen}
        onBackdropClick={onClose}
        anchorEl={anchorEl as RefObject<HTMLElement>}
        anchorOrigin={{horizontal: 'left'}}
        transformOrigin={{horizontal: 'left'}}
        zIndex={level + Level.Dropdown}
      >
        {render()}
      </Portal>
    );
  };

  return <>{anchorEl ? renderPopOver() : render()}</>;
};
